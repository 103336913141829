
  import { Component } from 'vue-property-decorator'
  import Cell from './cell'
  import { TextCellOptions } from '@/components/dataTables/cell/index'

@Component
  export default class ChipCell extends Cell {
  declare options: TextCellOptions

  get chips () {
    const { item } = this

    return item
  }

  get isMoreRoles () {
    return this.chips?.length > 6
  }
  }
